import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color vermell intens, d’himeni llis, en forma de copa de fins a 5 cm de diàmetre. Marge prim i pot presentar-se com esfilegassat o dentat. Té un peu més o menys rudimentari. Les espores són el·líptiques, en forma de fus, de 30-40 x 10-12 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      